import React from "react";
import "./about.css";
import about1 from "./img/about1.png";
import about2 from "./img/about2.png";
import { Grid } from "@mui/material";

const About = () => {
  return (
    <div className="about">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3}>
          {<img src={about1} alt="About" className="about-img1" />}
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          {<img src={about2} alt="About" className="about-img" />}
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <h1 className="grid-h1">BieSjefke</h1>
          <p className="grid-p">
            Welkom bij Taverne Bie Sjefke, waar smaak en gezelligheid samen
            brouwen tot een onvergetelijk feest voor jong en oud! Onze taverne
            is de perfecte plek voor families op zoek naar de ultieme gastvrije
            sfeer. Smul van een smakelijke lunch of een verrukkelijk diner van
            de bbq, gemaakt met liefde en verse ingrediënten uit het mooie
            Limburg.
          </p>
          <p className="grid-p">
            Bij Bie Sjefke draait alles om het creëren van een ontspannen en
            fijne omgeving waar families en vrienden samen kunnen genieten van
            lekker eten en elkaars gezelschap. Terwijl de volwassenen nippen van
            hun vers gezette koffie of vers getapt biertje, kunnen de kinderen
            zich uitleven in onze prachtige speeltuin en natuur speeltuin. Bij
            Taverne Bie Sjefke streven we ernaar om een plek te zijn waar
            iedereen zich thuis voelt en herinneringen kan creëren die een leven
            lang meegaan. Vergadering? Geen probleem, ook hier kunnen we jou of
            je bedrijf in faciliteren.
          </p>
          <p className="grid-p">
            Dus, schuif aan en ontdek de perfecte balans tussen lekker eten, een
            warme sfeer en familieplezier bij Bie Sjefke!
          </p>
        </Grid>
        {/* ...and so on */}
      </Grid>
    </div>
  );
};

export default About;
