import React from "react";
import "./Times.css";
import TimesPic from "./img/Times.png";

const Times = () => {
  return (
    <div className="Timesbackground">
      <img src={TimesPic} alt="TimesBackground" />
      <div className="openingtimes">
        <h1>Openingstijden</h1>

        <p>Di.-Zo. | 11:00 - 20:00</p>
        <h1>Keuken Openingstijden</h1>

        <p>Di.-Do. | 11:00 - 17:00</p>
        <p>Vr.-Zo. | 11:00 - 21:00</p>

       
      </div>
    </div>
  );
};

export default Times;
