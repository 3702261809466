import React from "react";
import "./WeekSpecial.css";
import specialimg from "./img/souep 1.png";
const Special = () => {
  return (
    <div className="week-specials">
      {/* <div className="special-description">
        <h1>Week’s Special</h1>
        <h3>Aardappel Soep</h3>
        <p>
          Deze week bij BieSjefke, laten we je smaakpapillen dansen met onze
          speciale lekkernijen!
          <br></br> <br></br>
          Geniet van de heerlijkste Soup van de Week, een kom vol
          seizoensgebonden smaken die je zintuigen strelen. Of kies voor het
          verrukkelijke Broodje van de Week, een perfect samengestelde creatie
          van verse ingrediënten. Kom langs en ontdek de culinaire verrassingen
          die onze chef deze week voor je in petto heeft. Een snelle en
          smaakvolle onderbreking van je dag wacht op je bij BieSjefke!
        </p>
      </div>
      <img src={specialimg} alt="Special" className="special-img" /> */}
    </div>
  );
};
export default Special;
