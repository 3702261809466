import React from "react";
import "./homepage.css";
import About from "./about";
import Special from "./WeekSpecial";
import Carousel from "./Comments";
import { Helmet } from "react-helmet";
import Arrangment from "./Arrangment";
import Contact from "./Contact";
import Times from "./Times";
import home from "./img/Home.png";

const Home = () => {
  const paragraphs = [
    '“Delicious food, the special toasted sandwich is really fantastic, almost a shame to call it a "toasti sandwich" because it is really delicious. The soup was very tasty and also a cheese sandwich is specially prepared. Parking was very nice. Beer tasted great!”',
    '"The place to be in VILT"',
    '"Heerlijk een drankje gedaan en super lekkere saté op. Bediening was erg vriendelijk en gastvrij. Leuke locatie."',
    '"Hele vriendelijke en professionele eigenaren en personeel. Bieden een totaal concept van Brasserie (met uitgebreide menukaart), terras, feest/evenementenhal, vergaderzalen, speeltuin en gratis parkeren. Ideaal als startplaats voor diverse sporten als wandelen en fietsen. Veilig stallen van fietsen en zelfs douchen behoort tot de mogelijkheden."',
  ];
  return (
    <div className="background">
      <img src={home} alt="HomePage" />
      <About />
      <Special />
      <Carousel paragraphs={paragraphs} />
      <Arrangment />
      <Contact />
      <Times />

      <Helmet>
        <script>
          {`
            (function(g,s,t,p,l,n){
              g["_gstpln"]={};
              (l=s.createElement(t)),(n=s.getElementsByTagName(t)[0]);
              l.async=1;l.src=p;n.parentNode.insertBefore(l,n);
            })(window,document,"script","https://cdn.guestplan.com/widget.js");
            _gstpln.accessKey = "48b9d987e7c9ca3f720da3d61fe620b9e92896fe";
            _gstpln.open = false;
          `}
        </script>
      </Helmet>
    </div>
  );
};

export default Home;
