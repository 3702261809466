import React from 'react';

const GoogleMap = () => {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d445.20028805128914!2d5.80731766066944!3d50.86018052491818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c0eb534cf0c391%3A0xcb570011231122!2sTaverne%20Bie%20Sjefke!5e0!3m2!1sen!2snl!4v1687448963467!5m2!1sen!2snl"
        width="400"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default GoogleMap;
