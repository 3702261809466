import React, { useState } from 'react';
import './Contact.css'
import GoogleMap from './GoogleMap'

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    // Set up the Formspree endpoint
    const endpoint = 'https://formspree.io/f/moqbbraz';

    // Create the FormData object
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('message', message);

    // Send the form data to Formspree
    fetch(endpoint, {
      method: 'POST',
      body: formData,
      headers: { 'Accept': 'application/json' }
    })
      .then(response => {
        if (response.ok) {
          console.log('Form submitted successfully');
          form.reset(); // Reset the form if the submission was successful
        } else {
          console.error('Form submission failed');
        }
      })
      .catch(error => {
        console.error('Form submission failed:', error);
      });
  };

  return (
    <div id="contact" className="contact-form">

        <div className="form-data">
<h1>Contact</h1>
<p className="address">Nachtegaalstraat 25, 6325 AX Berg en Terblijt,  Nederland
</p>

<p className="email">info@biesjefke.nl</p>
<p className="phone">06 117 716 94</p>
 <div className="maps"><GoogleMap /></div>


        </div>
        <div className="form-input">
      <form id="contact-form" onSubmit={handleSubmit}>
        <input
          name="name"
          type="text"
          className="form-control"
          placeholder="Uw Naam"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
       <br />
        <input
          name="email"
          type="email"
          className="form-control"
          placeholder="Uw Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

<br /> 

        <textarea
          name="message"
          className="form-control"
          placeholder="Bericht"
          rows="4"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <br />
        <input
          type="submit"
          className="form-control submit"
          value="Verstuur Bericht"
        />
      </form>
      </div>
    </div>
  );
};

export default Contact;
