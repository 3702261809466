import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./event.css";
// event img
import eventheader from "./img/events.png";
import fourdag from "./img/4dag.jpg";
import kofferbak from "./img/kofferbak markt.jpg";
import marathon from "./img/marathon.jpg";
import sinterklas from "./img/sinterklass.jpg";
import Kerstmarkt from "./img/kerstmarkt.jpg";
// past event
import avonddag from "./img/avond4.jpg";
import Kermis from "./img/kermis vilt.jpg";
import Tropical from "./img/tropicalsunday.jpg";
import Amstel from "./img/amstel gold race.jpg";
import zangkoor from "./img/zangor.jpg";
import Bucketpop from "./img/bucketpop.jpg";
import Carnaval from "./img/grotecaranavl.jpg";
import senior from "./img/senior.jpg";
import newyear from "./img/newyear.jpg";
import Buurtfeest from "./img/buurtfest.jpg";
import borrel from "./img/kerstborrel.jpg";
import Benefiet from "./img/benefitfiest.jpg";
import Halloween from "./img/halloween.jpg";
import bucketpop from "./img/bp2024.png";
import { Helmet } from "react-helmet";

// Function to convert date strings to a comparable format
const convertToDate = (dateString) => {
  const [day, month, year] = dateString.split("/");
  return new Date(`${year}-${month}-${day}`);
};

const Event = () => {
  const [showMore, setShowMore] = useState(false);

  const items = [
    {
      id: 1,
      title: "C.V. Dien mam ",
      description: "14/01/2024",
      imageUrl: Carnaval,
    },
    {
      id: 2,
      title: "Concert reeks Harmonie Amicititia",
      description: "06/04/2024",
      imageUrl: zangkoor,
    },
    {
      id: 3,
      title: "Amstel Gold race",
      description: "13/04/2024 , 14/04/2024",
      imageUrl: Amstel,
    },
    {
      id: 4,
      title: "ODS-classic",
      description: "31/08/2024",
      imageUrl: Benefiet,
    },
    {
      id: 5,
      title: "Bucketpop",
      description: "02/03/2024",
      imageUrl: bucketpop,
      link: "https://eventix.shop/v8q7r4nm",
    },
  ].sort((a, b) => convertToDate(a.description) - convertToDate(b.description));

  const pastItems = [
    {
      id: 22,
      title: "Kerstmarkt",
      description: "09/12/2023",
      imageUrl: Kerstmarkt,
    },
    {
      id: 21,
      title: "Sinterklaas Middag",
      description: "25/11/2023",
      imageUrl: sinterklas,
    },
    {
      id: 1,
      title: "Avond 4 daagse",
      description: "30/06/2023",
      imageUrl: avonddag,
    },
    {
      id: 2,
      title: "Kermis Vilt",
      description: "18/06/2023",
      imageUrl: Kermis,
    },
    {
      id: 3,
      title: "Tropical sunday",
      description: "25/06/2023",
      imageUrl: Tropical,
    },
    {
      id: 4,
      title: "Amstel Gold race",
      description: "15/03/2023",
      imageUrl: Amstel,
    },
    {
      id: 5,
      title: "Toneel Voorstelling",
      description: "03/03/2023",
      imageUrl: zangkoor,
    },
    {
      id: 6,
      title: "Bucketpop Benefiet avond",
      description: "11/03/2023",
      imageUrl: Bucketpop,
    },
    {
      id: 7,
      title: "Grote Carnaval optocht",
      description: "19/02/2023",
      imageUrl: Carnaval,
    },
    {
      id: 8,
      title: "Seniorenzitting",
      description: "I.S.M de Foekepotjes",
      imageUrl: senior,
    },
    {
      id: 9,
      title: "Nieuwjaarsborrel",
      description: "16/01/2023",
      imageUrl: newyear,
    },
    {
      id: 10,
      title: "Buurtfeest de Heiweg",
      description: "08/01/2023",
      imageUrl: Buurtfeest,
    },
    {
      id: 11,
      title: "Kerstborrel",
      description: "11/12/2022",
      imageUrl: borrel,
    },
    {
      id: 12,
      title: "Kerstmarkt",
      description: "11/12/2022",
      imageUrl: Kerstmarkt,
    },
    {
      id: 13,
      title: "Kien avond",
      description: "19/11/2022",
      imageUrl: senior,
    },
    {
      id: 14,
      title: "Sinterklaas Middag",
      description: "19/11/2022",
      imageUrl: sinterklas,
    },
    {
      id: 15,
      title: "Halloween",
      description: "29/10/2022",
      imageUrl: Halloween,
    },
    {
      id: 16,
      title: "Benefiet fietstocht",
      description: "01/10/2022",
      imageUrl: Benefiet,
    },
    {
      id: 17,
      title: "Concert zangkoor Amicita",
      description: "01/10/2022",
      imageUrl: zangkoor,
    },
    {
      id: 18,
      title: "Heuvelland 4 daagse",
      description: "13/08/2023",
      imageUrl: fourdag,
    },
    {
      id: 19,
      title: "Kofferbak markt",
      description: "17/09/2023",
      imageUrl: kofferbak,
    },
    {
      id: 20,
      title: "Geuldal marathon",
      description: "07/10/2023",
      imageUrl: marathon,
    },
  ].sort((a, b) => convertToDate(a.description) - convertToDate(b.description));

  pastItems.reverse();

  const visiblePastItems = showMore ? pastItems : pastItems.slice(0, 8);

  return (
    <div className="event-container">
      <div className="header">
        <img className="headerimg" src={eventheader} alt="Event" />
      </div>

      <div className="event-list">
        <h1 className="upcoming-event">Opkomende evenementen</h1>
        <div className="card-list">
          {items.map((item, index) => (
            <div
              key={item.id}
              className={`card ${!showMore && index >= 2 ? "hidden" : ""}`}
            >
              <div className="image-card">
                <img src={item.imageUrl} alt={item.title} />
                <div className="card-content">
                  <p className="card-title">{item.title}</p>
                  <p className="card-description">{item.description}</p>
                  {item.link && (
                    <button
                      className="card-button"
                      onClick={() => (window.location.href = item.link)}
                    >
                      Boek nu!
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="event-list">
        <h1 className="upcoming-event">Afgelopen evenementen</h1>
        <div className="card-list">
          {visiblePastItems.map((item, index) => (
            <div key={item.id} className={`card ${index >= 8 ? "hidden" : ""}`}>
              <div className="image-card">
                <img src={item.imageUrl} alt={item.title} />
                <div className="card-content">
                  <p className="card-title">{item.title}</p>
                  <p className="card-description">{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        {pastItems.length > 8 && (
          <button
            className="see-more-button"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? "See Less" : "See More"}
          </button>
        )}
      </div>
      <Helmet>
        <script>
          {`
            (function(g,s,t,p,l,n){
              g["_gstpln"]={};
              (l=s.createElement(t)),(n=s.getElementsByTagName(t)[0]);
              l.async=1;l.src=p;n.parentNode.insertBefore(l,n);
            })(window,document,"script","https://cdn.guestplan.com/widget.js");
            _gstpln.accessKey = "48b9d987e7c9ca3f720da3d61fe620b9e92896fe";
            _gstpln.open = false;
          `}
        </script>
      </Helmet>
    </div>
  );
};

export default Event;
