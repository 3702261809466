import React from "react";
import './info.css';
import voorwaardenImage from './img/voorwaarden.png'; 

const Info = () => {
    return (
        <div>
            <div className="img-container">
            <img src={voorwaardenImage} alt="voorwaarden" />
            </div>
            <div className="voorwaarden-info"> <p>
            ALGEMENE VOORWAARDEN en UVH <br></br> <br></br>

In ons bedrijf zijn de algemene voorwaarden uniforme voorwaarden horeca (UVH) van toepassing. Voor meer informatie, kijk op: Uniforme Voorwaarden Horeca <br></br>
<br></br>
Wilt u een factuur ontvangen? Dat kan! Graag ontvangen wij VOORAF alle juiste informatie, die op de rekening vermeld moet staan. Dit mag u telefonisch doorgeven op 06 11771694 of mailen naar info@biesjefke.nl.
<br></br> <br></br>
Afgifte visitekaartje bij het ondertekenen van de rekening stellen wij ten zeerste op prijs.
<br></br> <br></br>
Let op: Bij bedragen onder de € 150,00 brengen wij € 15,00 administratiekosten in rekening! Betaling dient te geschieden binnen 7 dagen na dagtekening factuur.
<br></br> <br></br>

Pictures by Pexels
</p>
            </div>
              
        </div>
    );
};

export default Info;
