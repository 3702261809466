import React from "react";
import "./Vacatures.css";
import headervacatures from "./img/vacatures.png";
import Bediening from "./img/tray.png";
import chef from "./img/chef 1.png";
import { Helmet } from "react-helmet";
const Vacatures = () => {
  const handleSoliciteerClick = () => {
    // Replace 'your_whatsapp_number' with the actual WhatsApp number you want to use
    // Replace 'your_message' with the message you want to send
    const whatsappLink = `https://wa.me/31611771694?text=Ik heb interesse in de vacature`;
    window.location.href = whatsappLink;
  };
  return (
    <div>
      <div className="headervacatures">
        <img src={headervacatures} alt="vacatures" />
      </div>
      <div className="vacature-paragraph">
        <h2>
          Ben je op zoek naar een uitdagende en dynamische carrière in de
          horeca?
        </h2>
        <p>
          Dan is een rol als bedieningsmedewerker misschien wel iets voor jou!
          Bij ons krijg je de kans om deel uit te maken van een gepassioneerd
          team dat er alles aan doet om onze gasten een onvergetelijke ervaring
          te bezorgen.
        </p>
      </div>
      <div className="vacatures">
        <img src={Bediening} alt="bediening" />
        <div className="info">
          <h2>Bediening</h2>
          <h3>Wat jij ons te bieden hebt:</h3>
          <ul>
            <li>Je draait al even mee in de horeca</li>
            <li>Je bent gedreven en hebt ambitie</li>
            <li>Je levert jouw bijdrage aan de beleving van de gast</li>
            <li>Je durft de leiding te nemen tijdens jouw shift</li>
            <li>Je draait je hand niet om voor een woordje Engels</li>
          </ul>
          <h3>Wat jij zoekt:</h3>
          <ul>
            <li>
              Een fulltime baan voor 38 uur (parttime bespreekbaar) per direct
            </li>
            <li>Trainings- en opleidingsmogelijkheden binnen jouw vakgebied</li>
            <li>Een jong en talentvol team</li>
            <li>Flexibiliteit en bespreekbare, vaste werktijden</li>
            <li>Een vast contract (evt. onbepaalde tijd)</li>
            <li>Een goed salaris dat past bij jouw kwaliteiten</li>
            <button className="soliciteer-btn" onClick={handleSoliciteerClick}>
              Soliciteer
            </button>
          </ul>
        </div>
      </div>

      <div className="chef">
        <div className="chef-info">
          <h2>Chef</h2>
          <h3>Wat jij ons te bieden hebt:</h3>
          <ul>
            <li>Je draait al even mee in de horeca</li>
            <li>Je bent gedreven en hebt ambitie</li>
            <li>Je levert jouw bijdrage aan de beleving van de gast</li>
            <li>Je durft de leiding te nemen tijdens jouw shift</li>
            <li>Je draait je hand niet om voor een woordje Engels</li>
          </ul>
          <h3>Wat jij zoekt:</h3>
          <ul>
            <li>
              Een fulltime baan voor 38 uur (parttime bespreekbaar) per direct
            </li>
            <li>Trainings- en opleidingsmogelijkheden binnen jouw vakgebied</li>
            <li>Een jong en talentvol team</li>
            <li>Flexibiliteit en bespreekbare, vaste werktijden</li>
            <li>Een vast contract (evt. onbepaalde tijd)</li>
            <li>Een goed salaris dat past bij jouw kwaliteiten</li>
            <button className="soliciteer-btn" onClick={handleSoliciteerClick}>
              Soliciteer
            </button>
          </ul>
        </div>
        <img src={chef} alt="chef" />
      </div>

      <Helmet>
        <script>
          {`
            (function(g,s,t,p,l,n){
              g["_gstpln"]={};
              (l=s.createElement(t)),(n=s.getElementsByTagName(t)[0]);
              l.async=1;l.src=p;n.parentNode.insertBefore(l,n);
            })(window,document,"script","https://cdn.guestplan.com/widget.js");
            _gstpln.accessKey = "48b9d987e7c9ca3f720da3d61fe620b9e92896fe";
            _gstpln.open = false;
          `}
        </script>
      </Helmet>
    </div>
  );
};

export default Vacatures;
