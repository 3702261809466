import React from 'react';
import './Footer.css';
import logo from './img/logonew.png';
import { FaInstagram, FaFacebook, FaInfo } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer">
      <img src={logo} alt="logo" className="logo-img" />
      <p>
        &copy; Taverne bieSjefke |{' '}
        <span className="social-icons">
          <a href="https://www.instagram.com/biesjefke/" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="icon" />
          </a>
          <a href="https://www.facebook.com/p/Taverne-Bie-Sjefke-100086508430551/" target="_blank" rel="noopener noreferrer">
            <FaFacebook className="icon" />
          </a>
          <Link to="/info" className="icon">
            <FaInfo className="icon" />
          </Link>
        </span>
      </p>
    </div>
  );
};

export default Footer;
