import React, { useState, useEffect } from "react";
import "./Comments.css"; // Import CSS file for styling

const Carousel = ({ paragraphs }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === paragraphs.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? paragraphs.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const timer = setInterval(() => {
      handleNext();
    }, 10000); // 20 seconds

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="comments">
      <h1>Wat onze gasten zeggen...</h1>
      <div className="carousel-container">
        <button className="previous-button" onClick={handlePrevious}>
          <span className="icon-comments">&lt;</span>
        </button>
        <div className="paragraph">{paragraphs[currentIndex]}</div>
        <button className="next-button" onClick={handleNext}>
          <span className="icon-comments">&gt;</span>
        </button>
      </div>
    </div>
  );
};

export default Carousel;
