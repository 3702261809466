import React, { useState } from "react";
import "./navbar.css";
import logo from "./img/BieSjefke_Logo_Zomer_RGB-removebg-preview (1).png";
import pdfMenu from "./pdf/Menu.pdf";
import DinerKaart from "./pdf/Dinerkaart.pdf";
import Lunchkaart from "./pdf/Lunchkaart.pdf";
import Kerstmenu from "./pdf/Kerstmenu.pdf";
import Event from "./event";
import {
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CDropdownDivider,
} from "@coreui/react";

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;

    // Navigate to the corresponding PDF link in a new tab with the name "Menu"
    if (selectedValue !== "") {
      let pdfLink = pdfMenu; // default link is Menu.pdf

      switch (selectedValue) {
        case "Diner Kaart":
          pdfLink = DinerKaart;
          break;
        case "Lunch Kaart":
          pdfLink = Lunchkaart;
          break;
        case "Kerst Menu":
          pdfLink = Kerstmenu;
          break;
        default:
          break;
      }

      window.open(pdfLink, "_blank", "menubar=0");
    }

    // Reset the selected option to an empty string
    setSelectedOption("");
  };

  return (
    <nav className={`navbar ${isMobileMenuOpen ? "mobile-menu-open" : ""}`}>
      <div
        className={`navbar-hamburger ${isMobileMenuOpen ? "active" : ""}`}
        onClick={toggleMobileMenu}
      >
        <div className={`hamburger-bar ${isMobileMenuOpen ? "open" : ""}`} />
        <div className={`hamburger-bar ${isMobileMenuOpen ? "open" : ""}`} />
        <div className={`hamburger-bar ${isMobileMenuOpen ? "open" : ""}`} />
      </div>

      <div className="navbar-items">
        <div className="navbar-items-left">
          <a href="/">Home</a>
{/*         
          <select
            id="menuDropdown"
            value={selectedOption}
            onChange={handleDropdownChange}
            style={{ margin: "0", paddingLeft: "10px" }}
          >
            <option value="">Menu</option>
            <option value="option1">Diner Kaart</option>
            <option value="option2">Lunch Kaart</option>
            <option value="option3">Kerst Menu</option>
          </select> */}
        </div>

        <div className="navbar-logo">
          <a href="/">
            <img src={logo} alt="Logo" />
          </a>
        </div>

        <div className="navbar-items-right">
          <a href="Vacatures">Vacature</a>
          <a href="Event">Events</a>
        </div>
      </div>

      {/* Mobile menu wrapper */}
      <div className="navbar-wrapper">
        <div className="navbar-logomobile">
          <a href="/">
            <img src={logo} alt="Logo" />
          </a>
        </div>
        <div
          className={`mobile-menu-wrapper ${isMobileMenuOpen ? "active" : ""}`}
        >
          <div className="mobile-menu-close" onClick={toggleMobileMenu}>
            {/* Cross icon for closing */}
            <div
              className={`hamburger-bar ${isMobileMenuOpen ? "cross" : ""}`}
            />
            <div
              className={`hamburger-bar ${isMobileMenuOpen ? "cross" : ""}`}
            />
          </div>
          <div className="mobile-menu-items">
            <a href="/">Home</a>
            {/* Include the same dropdown in the mobile menu */}
            <select
              id="mobileMenuDropdown"
              value={selectedOption}
              onChange={handleDropdownChange}
              className="custom-dropdown"
            >
              <option value="">Menu</option>
              <option value="Diner Kaart">Diner Kaart</option>
              <option value="Lunch Kaart">Lunch Kaart</option>
              <option value="Kerst Menu">Kerst Menu</option>
            </select>
            <a href="Vacatures">Vacature</a>
            <a href="Event">Events</a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
