import React from "react";
import "./Arrangment.css";
import ArrangmentPic from "./img/arrangment.png";

const Arrangment = () => {
  const handleSoliciteerClick = () => {
    // Replace 'your_whatsapp_number' with the actual WhatsApp number you want to use
    // Replace 'your_message' with the message you want to send
    const whatsappLink = `https://wa.me/31611771694?text=Ik wil graag meer informatie over de arrangementen`;
    window.location.href = whatsappLink;
  };
  return (
    <div className="Arrangment-background">
      <div className="Arrangment-overlay">
        <div className="Arrangment-description">
          <h1>Arrangementen</h1>
          <p>
            Verken ons uitstekende menu, gevuld met dagverse lekkernijen gemaakt
            van ambachtelijke streekproducten. Wij bieden een scala aan
            culinaire ervaringen, van een smakelijke lunch tot een uitgebreid
            authentiek diner. Neem gerust een kijkje!
          </p>
          <button className="soliciteer-btn" onClick={handleSoliciteerClick}>
            Arrangementen
          </button>
        </div>
      </div>
      <img className="Arrangment-hero" src={ArrangmentPic} alt="Arrangment" />
    </div>
  );
};

export default Arrangment;
